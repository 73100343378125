import contact02 from '../assets/img/contact02.png';
import contact03 from '../assets/img/contact03.png';
import contact04 from '../assets/img/contact04.png';
import contact05 from '../assets/img/contact05.png';
import contact06 from '../assets/img/contact06.png';
import contact07 from '../assets/img/contact07.png';
import contact08 from '../assets/img/contact08.png';
import contact09 from '../assets/img/contact09.png';
import contact11 from '../assets/img/contact11.png';
import contact12 from '../assets/img/contact12.png';
import contact13 from '../assets/img/contact13.png';
import contact14 from '../assets/img/contact14.png';
import contact15 from '../assets/img/contact15.png';
import contact16 from '../assets/img/contact16.png';
import contact17 from '../assets/img/contact17.png';
import contact18 from '../assets/img/contentmap.png';
import contact19 from '../assets/img/contact18.png';
import contact20 from '../assets/img/contact19.png';
import contact21 from '../assets/img/contact20.png';

export const Banner30DataSource = { //1
    wrapper: {
        className: 'banner3 contact-banner3 banner-phone-size'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '奉献智慧能源 缔造清洁世界',
            texty: true,
        },],
    },
};
export const Feature10DataSource = { //2
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-wrapper contact-auto-height green-line-style'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '关于东润环能'
    },
    content: {
        className: 'content1-content',
        children: 'company',
    },
};
export const Content90DataSource = { //3
    wrapper: {
        className: 'home-page-wrapper content9-wrapper contact-content9-wrapper green-line-style licheng-contact-content9-wrapper'
    },
    page: {
        className: 'home-page content9'
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'image',
            children: '',
            className: 'title-image',
        }, {
            name: 'title',
            children: '发展历程',
            className: 'title-h1'
        },],
    },
    block: {
        className: 'timeline',
        children: [{
            name: 'block0',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: '',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2009'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2009年'
                },
                content: {
                    className: 'block-content',
                    children: '东润环能（北京）科技公司在北京注册成立'
                },
            },
        },
        {
            name: 'block1',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2011'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2011年'
                },
                content: {
                    className: 'block-content',
                    children: '获得国家高新企业认证 ',
                },
            },
        },
        {
            name: 'block1',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2012'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2012年'
                },
                content: {
                    className: 'block-content',
                    children: '获得北京市企业科技研究开发机构',
                },
            },
        },
        {
            name: 'block1',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2013'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2013年'
                },
                content: {
                    className: 'block-content',
                    children: '东润环能股份制改革成功更名北京东润环能科技股份有限公司。',
                },
            },
        },
        {
            name: 'block2',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2014'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2014年'
                },
                content: {
                    className: 'block-content',
                    children: '东润环能在“新三版”挂牌成功，股票代码：831083  ',
                },
            },
        }, {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2015'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2015年'
                },
                content: {
                    className: 'block-content',
                    children: '东润环能完成第二轮募资，收购3项资产',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2016'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2016年'
                },
                content: {
                    className: 'block-content',
                    children: '完成与阿里云签订战略合作协议',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2017'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2017年'
                },
                content: {
                    className: 'block-content',
                    children: '东润环能与著名PE鼎晖合作，启动战略并购',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2018'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2018年'
                },
                content: {
                    className: 'block-content',
                    children: ' 签订安徽滁州、池州、六安等地调管理支持系统项目',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2019'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2019年'
                },
                content: {
                    className: 'block-content',
                    children: '签订国电、大唐、特变电工等一次调频项目项目',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2020'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2020年'
                },
                content: {
                    className: 'block-content',
                    children: '获得电力科技创新二等奖',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2021'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2021年'
                },
                content: {
                    className: 'block-content',
                    children: '签订江西国电投集中智能功率预测系统项目',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '2022'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '2022年'
                },
                content: {
                    className: 'block-content',
                    children: '被评为北京市“专精特新”和北京市专精特新“小巨人”企业',
                },
            },
        },
        {
            name: 'block3',
            className: 'block-wrapper dr-block-wrapper',
            playScale: 0.3,
            children: {
                imgWrapper: {
                    className: 'image-wrapper'
                },
                textWrapper: {
                    className: 'text-wrapper'
                },
                img: {
                    className: 'block-img',
                    children: 'https://gw.alipayobjects.com/zos/rmsportal/SlFgHDtOTLzccvFrQHLg.png',
                },
                icon: {
                    className: 'block-icon',
                    children: '',
                },
                name: {
                    className: 'block-name',
                    children: ''
                },
                post: {
                    className: 'block-post',
                    children: '至今'
                },
                time: {
                    className: 'block-time',
                    children: ''
                },
                title: {
                    className: 'block-title',
                    children: '至今'
                },
                content: {
                    className: 'block-content',
                    children: '累计服务项目超过3000+，聚焦新能源数据服务市场',
                },
            },
        }
        ],
    },
};
export const Content50DataSource = { //4
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg contant-main-height green-line-style'
    },
    page: {
        className: 'home-page content5'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '合作伙伴',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '',
        },],
    },
    block: {
        className: 'content5-img-wrapper cyuce-imgstyle',
        gutter: 16,
        children: [{
            name: 'block0',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact02,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block1',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact03,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block4',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact04,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block5',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact05,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block6',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact06,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block7',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact07,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block8',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact08,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block9',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact11,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block10',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact09,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block11',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact12,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block12',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact13,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block13',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact14,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block14',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact15,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block15',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact16,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block16',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact17,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block17',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact19,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block18',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact20,
                },
                content: {
                    children: ''
                },
            },
        }, {
            name: 'block19',
            className: 'block',
            md: 4,
            xs: 24,
            children: {
                wrapper: {
                    className: 'content5-block-content'
                },
                img: {
                    children: contact21,
                },
                content: {
                    children: ''
                },
            },
        },],
    },
};
//关于我们联系我们
export const Feature20DataSource = { //5
    wrapper: {
        className: 'home-page-wrapper content2-wrapper dr-contact-content2-wrapper  agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 15,
        xs: 24
    },
    img: {
        children: contact18,
    },
    textWrapper: {
        className: 'content2-text',
        md: 9,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '联系我们'
    },
    content: {
        className: 'content2-content dr-contact-content2-content',
        children: '北京东润环能科技股份有限公司',
        children1: '电话：+86（10）82732720 ',
        children2: '传真：+86（10）82311607 ',
        children3: '邮箱：zhengyantao@eeechina.cn',
        children4: '地址：北京市海淀区中关村丹棱街互联网金融中心B座9层',
        children5: '服务热线：',
    },
};